<template>
  <div id="container">

    <section id="register">
      <div class="wrap">
        <div class="register_step flex">
          <div class="step_box flex on">
            <div class="num">1</div>
            <p>塾生情報の登録</p>
          </div>
          <div class="step_box flex on">
            <div class="num">2</div>
            <p>ご住所の登録</p>
          </div>
          <div class="step_box flex on">
            <div class="num">3</div>
            <p>パスワード設定</p>
          </div>
          <div class="step_box flex on">
            <div class="num">4</div>
            <p>入力内容の確認</p>
          </div>
          <div class="step_box flex on">
            <div class="num">5</div>
            <p>入塾金の<br>支払いへ進む</p>
          </div>
        </div>

        <form action="" v-if="new_user">

          <div class="cts_box">
            <h2 class="cts_ttl"><span>入塾金の支払いへ進む</span></h2>
            <div class="thanks_lead">
              <p class="aln_C sp_alnL">
                クレジットカードの登録を行います。<br>
                今回の決済は入塾金（１万円・税込）のみです。<br>
                月謝の引き落とし決済は入塾日（来月１日）より開始されます。
              </p>
            </div>
            <div class="button_wrap">
              <a v-bind:href="payment.url" class="submit basic arrow h_auto">クレジットカードの登録および<br>入塾金の支払い手続きに進む</a>
            </div>
            <div class="paygent flex">
              <img src="/common/images/paygent_logo.png" alt="PAYGENT">
              <p>
                クレジットカード決済サービス会社（PAYGENT）の決済画面に移動します。PAYGENTは、NTTデータと三菱UFJニコスの合弁による国内大手決済サービス会社です。
              </p>
            </div>
          </div>

          <div class="cts_box">
            <h2 class="cts_ttl"><span>お申込み内容</span></h2>
            <p class="lead_txt alnC sp_alnL">念のため内容に誤りが無いかご確認下さい。</p>
            <div class="confirm_list">
              <dl class="flex">
                <dt>氏名</dt>
                <dd>{{ new_user.last_name }} {{ new_user.first_name }}</dd>
              </dl>
              <dl class="flex">
                <dt>ユーザーネーム（表示名）</dt>
                <dd>{{ new_user.sns_user_name }} </dd>
              </dl>
              <dl class="flex">
                <dt>性別</dt>
                <dd>{{ new_user.gender_label }}</dd>
              </dl>
              <dl class="flex">
                <dt>生年月日</dt>
                <dd>{{ new_user.birthday_year }}年 {{ new_user.birthday_month }}月 {{ new_user.birthday_day }}日</dd>
              </dl>
              <dl class="flex">
                <dt>住所</dt>
                <dd>〒{{ new_user.zip }}<br>{{ new_user.prefecture }} {{ new_user.address1 }} {{ new_user.address2 }}<br>
                  {{ new_user.address3 }}
                </dd>
              </dl>
              <dl class="flex">
                <dt>メールアドレス</dt>
                <dd>{{ new_user.email }}</dd>
              </dl>
              <dl class="flex">
                <dt>電話番号</dt>
                <dd>{{ new_user.phone_number }}</dd>
              </dl>
              <dl class="flex">
                <dt>ご希望のクラス</dt>
                <dd>{{ new_user.rank_name }}</dd>
              </dl>
              <dl class="flex">
                <dt>四季報ID</dt>
                <dd>{{ new_user.shikiho_account ? new_user.shikiho_account : '入力なし' }}</dd>
              </dl>
              <dl class="flex">
                <dt>送付先住所</dt>
                <dd v-if="new_user.enable_primary_address">〒{{ new_user.zip }}<br>{{ new_user.prefecture }}
                  {{ new_user.address1 }} {{ new_user.address2 }}<br> {{ new_user.address3 }}
                </dd>
                <dd v-if="!new_user.enable_primary_address">〒{{ new_user.opt_zip }}<br>{{ new_user.opt_prefecture }}
                  {{ new_user.opt_address1 }} {{ new_user.opt_address2 }}<br> {{ new_user.opt_address3 }}
                </dd>
              </dl>
            </div>

            <div class="cts_box">
              <div class="thanks_lead">
                <p class="aln_C sp_alnL">
                  クレジットカードの登録を行います。<br>
                  今回の決済は入塾金（１万円・税込）のみです。<br>
                  月謝の引き落とし決済は入塾日（来月１日）より開始されます。
                </p>
              </div>
              <div class="button_wrap">
                <a v-bind:href="payment.url" class="submit basic arrow h_auto">クレジットカードの登録および<br>入塾金の支払い手続きに進む</a>
              </div>
              <div class="paygent flex">
                <img src="/common/images/paygent_logo.png" alt="PAYGENT">
                <p>
                  クレジットカード決済サービス会社（PAYGENT）の決済画面に移動します。PAYGENTは、NTTデータと三菱UFJニコスの合弁による国内大手決済サービス会社です。
                </p>
              </div>
            </div>

          </div>

        </form>
      </div><!--//.wrap-->
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  components: {},
  data() {
    return {
      new_user: null,
      payment: null
    }
  },

  computed: {
    //
  },

  mounted() {
    this.axios
        .post(`${this.env.api_base_url}users/confirmation_finish.json`, {
          confirmation_token: this.$route.params.token,
        })
        .then(response => {
          this.new_user = response.data.user
          this.payment = response.data.payment
          //this.$forceUpdate()
        })
        .catch(error => {
          if (error.response.data && error.response.data.error) {
            this.setFlash(error.response.data.error)
            this.$router.push({
              name: 'Home'
            })
          }
        });
  },
  methods: {
  }
}
</script>
